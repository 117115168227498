#header {
  position: sticky;
  top: 0;
  z-index: 1000;
}

#header header {
  z-index: 100;
  width: 100%;
  white-space:nowrap;
  margin: 0;
  background-color: #0e8e80;
  display: flex;
  align-items: center;
  justify-content: center;
}

#header-item-list {
  display: flex;
  flex-direction: row;
  /* flex-grow: 1; */
  justify-content: center;
}

#header__dropdown {
  position: relative;
  display: flex;
  justify-content: center;
  text-align: center;
}

#header__dropdown__wrapper {
  width: 100%;
  position: absolute;
}

#logo__desktop, #mobile-logo {
  padding-right: 1em;
  padding-left: 1em;
  font-size: 1.4em;
  background: linear-gradient(90deg, #0e8e80,  #F29169, #0e8e80, #F29169, #0e8e80);
  background-repeat: no-repeat;
  background-size: 80%;
  animation: swipe 3s linear infinite;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(246, 220, 203, 0.70);
}

#logo__desktop {
  padding-left: 0 !important
}

#mobile-logo {
  font-size: 24px;
}

#header__dropdown div {
  margin: 0.5em;
}

#header__dropdown {
  background-color: rgba(10, 154, 138, 0.95);
}

@supports ((-webkit-backdrop-filter: blur(2em)) or (backdrop-filter: blur(2em))) {
  #header__dropdown {
    background-color: rgba(10, 154, 138, 0.70);
    backdrop-filter: blur(10px);
  }
}

.hide-dropdown {
  position: absolute;
  top: -10000px;
  width: 0px; height: 0px;
  display: none;
  visibility: hidden;
}

.slide-in {
  position: absolute;
  transform: translateY(-100%);
  -webkit-animation: slide 0.5s forwards;
  -webkit-animation-delay: 2s;
  animation: slide 0.5s forwards;
  animation-delay: 0s;
}

@-webkit-keyframes slide {
  0% {
    z-index: -1;
  }
  99% {
    z-index: -1;
  }
  100% {
    z-index: 1;
    transform: translateY(0%);
  }
}

@keyframes slide {
  0% {
    z-index: -1;
  }
  99% {
    z-index: -1;
  }
  100% {
    z-index: 1;
    transform: translateY(0%);
  }
}

@-webkit-keyframes swipe {
  0% {
    background-position: -500%;
  }
  50% {
    background-position: 500%;
  }
  100% {
    background-position: -500%;
  }
}

@keyframes swipe {
  0% {
    background-position: -500%;
  }
  50% {
    background-position: 500%;
  }
  100% {
    background-position: -500%;
  }
}

#header header h1 {
  font-family: benfont;
  color: #f6dccb;
}

#header__mobile_dummy {
  width: 2em;
}

.button-container__desktop {
  padding-left: 1em;
  padding-right: 1em;
}

.button-hl {
  color: #359588 !important;
  background: #EAEBF3;
  border-radius: 0.4em;
  flex-shrink: 5;
  padding: .4em;
  font-size: 1.1em !important;
}

.button-hl:hover {
  color:#F29169 !important;
}

.button-container__mobile {
  margin-left: 0.8em;
  margin-right: 0.8em;
}

.button-content__mobile {
  padding: 5px;
  background-color: #F29169;
  border-radius: 100px;
  color: #707070;
  width: 2em;
  height: 2em;
  font-size: 1.2em;
}

.button-content__desktop:visited {
  color: #f6dccb
}

.button-content__desktop:hover, .row-container, #logo__desktop {
  cursor: pointer;
  color: #F29169 !important;
}

.button-content__desktop {
  font-family: 'Hey Fun';
  font-size: calc(1em + .1vw);
  color: #f6dccb;
}

.button-content {
  border: none;
  margin: auto;
  box-shadow: 4px 4px 10px rgba(97, 84, 79, 0.842),
    -4px -4px 10px rgba(232, 241, 239, 0.356);
}

.header__dropdown__spacer {
  background: #FE9268;
  border-radius: 4px;
  height: 4px;
}

.button-content, .row-content {
  font-family: 'Hey Fun';
}

.row-content {
  /* font-weight: 600; */
  color: white !important;
  font-size: 1.3em;
  text-decoration: none;
  width: 100%;
}

.button-content:hover {
  /* background-color: #c7653e; */
    box-shadow: 2px 2px 8px rgba(97, 84, 79, 0.842),
    -2px -2px 8px rgba(232, 241, 239, 0.356);
}

.button-content:active, .due-landing__dorito-btn:active  {
    box-shadow: inset 4px 4px 7px rgba(97, 84, 79, 0.842),
      inset -4px -4px 7px rgba(232, 241, 239, 0.356);
}

.row-container {
  height: 1.8em;
  width: 12em;
}

.row-container:active {
  border-radius: 5px;
  box-shadow: inset 4px 4px 7px rgba(11, 116, 105, 0.7);
}

.row-content:active {
  font-size: 1.2em;
}

.hl a{
  color: #359588 !important;
  background: #EAEBF3;
  padding: .2em;
  border-radius: .6em;
}

.unselectable, #header, #due-landing__features {
  -webkit-tap-highlight-color: transparent;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
