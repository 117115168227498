  body{
    background-color:#575B76;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
}
a{
    text-decoration:none;
}
#player-exp{
    margin-bottom:12px;
}
.edit-box{
    width:750px!important;
    flex-grow:0.18!important;
    flex-shrink:0.18!important;
}
#partner-details div{
    margin-bottom:8px;
}
.longText{
    overflow-wrap:break-word;
    word-wrap:break-word;
    -ms-word-break:break-all;
    word-break:break-all;
    word-break:break-word;
    -ms-hyphens:auto;
    -moz-hyphens:auto;
    -webkit-hyphens:auto;
    hyphens:auto;
}
#create-partner div{
    margin-bottom:8px;
}
#exp-message{
    margin-bottom:4px;
}
.thing-name{
    overflow:hidden;
    text-overflow:ellipsis;
    max-width:106px;
    white-space:nowrap;
    display:inline-block;
    vertical-align:top;
}
.aliases{
    display:inline-block;
    margin-top:12px;
    margin-bottom:0px;
}
#settings{
    text-align:left;
}
#settings-form{
    background-color:#575B76;
    padding:6px;
    margin-top:-10px;
    color:rgba(249,241,223,.87);
}
#profile-link{
    color:#95d3bd;
    background:#6c708b;
}
.greyed-out{
    opacity:0.5;
    pointer-events:none;
    cursor:default;
}
#settings-message{
    color:rgba(249,241,223,.87);
}
.cete-popup__content{
    margin-top:-8px;
}
code{
    font-family:Menlo,Consolas,Monaco,monospace;
    font-size:14px;
    line-height:16px;
    padding:1px;
    border-radius:1px;
    padding-left:2px;
    padding-right:2px;
    border-radius: 3px;
    background-color: #36393e85;
}
.discord-message {
  background-color:#36393E;
}
.discord-message{
    border:1px #3E4146;
    padding:10px;
    display:inline-block;
    border-radius:4px;
    flex-grow:0;
    align-self:flex-start;
}
.mention{
    background-color:rgba(115,139,215,.1);
    color:#7289da;
    background:#f1f3fb;
    font-weight:500;
    transition:background-color .05s,color .05s;
}
.centre-vert-content{
    display:table-cell;
    vertical-align:middle;
}
.frame{
    height:80%;
    width:100%;
    white-space:nowrap;
    text-align:center;
    margin:1em 0;
}
.center-both{
    display:flex;
    justify-content:center;
    align-items:center;
    flex-shrink:0;
    flex-flow:row wrap;
}
.helper{
    display:inline-block;
    height:65%;
    vertical-align:middle;
}
.center-img{
    vertical-align:middle;
    width:100%;
    text-align:center;
}
.bottom-buttons{
    position:absolute;
    bottom:0px;
    width:calc(100% - 48px);
}
.due-emoji-th{
    font-size:18px;
    position:absolute;
    top:17px;
}
#profile-link,#delete-cnf{
    height:250px;
    width:400px;
    z-index:11;
    visibility:visible;
    display:none;
}
.weapon-icon{
    height:28px;
    width:28px;
    border:solid #252B34;
    border-width:1px;
}
.weapon-card{
    background:#575B76;
    display:inline-block;
    padding:6px;
    margin-top:12px;
    margin-right:8px;
    width:230px;
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
    width:230px;
    height:80px;
}
#current-user-name{
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
    width:100%;
}
/* #myweapons,#myawards{
    min-height:367px;
    max-width:500px;
    min-width:500px;
    max-height:420px;
    flex:0 0 auto;
} */
.wrapped{
    max-width:800px!important;
    flex-basis:700px;
    flex:1 1 auto!important;
    height:auto!important;
    min-height:274px!important;
}
.cete-user-info{
    width:65%;
}
.error-image{
    width:400px;
    height:auto;
    flex-shrink:0;
}
#weapon-list{
    position:relative;
    right:-4px;
    padding-left:0px;
}
.player-stats-dash{
    margin-top:-20px;
    font-size:24px;
    margin-bottom:12px;
}
.quest-index{
    position:absolute;
    min-width:10px;
    text-align:center;
}
.player-icon-inline{
    margin-left:6px;
    margin-right:6px;
}
.weapon-slot{
    padding:6px;
    margin-top:12px;
    margin-right:8px;
    /* background-image:url('/web/20170829025830im_/https://dueutil.tech/img/weapon-slot.png'); */
    background-repeat:no-repeat;
    background-position:8px 8px;
}
.hit-message{
    background-color:#36393E;
    border:solid #252B34;
    border-width:1px;
    border-radius:2px;
    padding-left:2px;
    padding-right:2px;
    margin-top:4px;
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
}
.weapons-inner{
    height:100%;
    width:100%;
}
#content-title{
    text-align:center;
}
.center{
    -webkit-transform:translate(-50%,-0%);
    transform:translate(-50%,-0%);
    position:absolute!important;
    left:50%;
}
.selectfield{
    text-align:left!important;
}
.level{
    background:#95d3bd;
    color:#575c75;
    padding:2px;
    white-space:nowrap;
    border-radius:1px;
}
.partner-type{
    padding:2px;
    white-space:nowrap;
    display:inline;
    border-radius:1px;
    vertical-align:top;
    text-transform:capitalize;
}
.bot{
    background:#7289DA;
    color:#FFFFFF;
}
.server{
    background:#43B581;
    color:#FFFFFF;
}
.other{
    background:#EDA234;
    color:#FFFFFF;
}
.damage{
    background:rgba(255,105,97,0.55);
    padding:2px;
    white-space:nowrap;
    border-radius:1px;
}
.weapon-name{
    width:180px;
}
.accy{
    background:rgb(105,137,185);
    padding:2px;
    white-space:nowrap;
    border-radius:1px;
}
.weapon-stats{
    margin-top:4px;
    margin-right:4px;
}
.member-list{
    margin-top:24px;
    margin-left:12px;
    margin-right:12px;
    background:#1a344b;
    box-shadow:0 2px 2px 0 rgba(0,0,0,.14),0 3px 1px -2px rgba(0,0,0,.2),0 1px 5px 0 rgba(0,0,0,.12);
}
.page-content,#home-buttons{
    display:-webkit-flex;
    display:flex;
    flex-shrink:0;
    flex-grow:0;
    flex-direction:column;
    -webkit-align-items:center;
    align-items:center;
    -webkit-justify-content:center;
    justify-content:center;
    -webkit-flex-direction:row;
    flex-direction:row;
    -webkit-flex-wrap:wrap;
    flex-wrap:wrap;
    -webkit-flex-flow:row wrap;
    flex-flow:row wrap;
    -webkit-align-content:flex-end;
    align-content:flex-end;
    margin:0 auto;
    max-width:100em;
}
#home-buttons{
    flex-shrink:0.8;
    width:580px;
}
#home-buttons>a{
    display:flex;
    flex-shrink:0.5;
    justify-content:center;
    width:34.729%;
}
.width-limit{
    margin:0 auto;
    max-width:100em;
}
.member-li:hover{
    cursor:pointer;
}
#sidebar-user{
    white-space:nowrap;
}
.extra-info{
    padding-left:10px;
    display:flex;
    align-items:center;
    flex-shrink:2;
    flex:1 1 260px;
    min-width:260px;
}
.maccy-tip,.partner-message{
    background-color:#16cdb975;
    border:1px #858aaa;
    padding:10px;
    border-radius:4px;
}
.flex-content-row{
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction:row;
    flex-direction:row;
    /* background-color:#6c708b; */
    /* margin-top:12px;
    margin-right:12px; */
    margin-top: 12px;
    margin-bottom: 12px;
    flex-flow:row wrap;
}
.flex-content{
    flex-basis:auto;
    flex-grow:1;
    background-color:#6c708b;
    /* margin-top:12px;
    margin-right:12px; */
}
.due-link{
    color:#95d3bd!important;
}
#user-info-header{
    /* background:url(/web/20170829025830im_/https://dueutil.tech/img/pattern.svg); */
    background-size:140px 140px;
    background-color:#CDDAF2;
    margin-top:-24px;
    margin-left:-24px;
    margin-right:-24px;
    margin-bottom:8px;
    padding:12px 24px 4px;
}
#user-info-avatar{
    float:none;
    width:80px;
    height:auto;
    margin-bottom:4px;
    border-width:3px;
    border-color:#7B96AB;
    margin:0 auto;
}
.img-default{
    border-radius:50%;
    border-style:solid;
    border-color:#797d98;
    border-width:5px;
    width:200px;
    height:200px;
    margin:auto;
    margin-top:12px;
}
#partner-page-content{
    width:100%;
    margin-top:12px;
    text-align:center;
}
#user-info-name{
    font-weight:600;
    color:#476178;
}
#sidebar-info{
    height:206px;
    width:200px;
    z-index:11;
    visibility:visible;
    display:none;
    left:10px;
    right:10px;
    text-align:center;
    top:0px;
    left:0;
    right:0;
    margin-left:0;
    margin-right:0;
    -webkit-transform:translateY(0%);
    transform:translateY(0%);
    -ms-transform:translateY(0%);
    webkit-transform:translateY(0%);
    box-shadow:0 2px 2px 0 rgba(0,0,0,.14),0 3px 1px -2px rgba(0,0,0,.2),0 1px 5px 0 rgba(0,0,0,.12);
}
.member-list>.mdl-layout-title{
    padding-top:16px;
    padding-left:16px;
    padding-bottom:-12px;
}
.center-all{
    height:100%;
    display:table!important;
}
.mdl-dialog__actions{
    position:fixed;
    bottom:15px;
    right:15px;
}
.center-all-inner{
    height:100%;
    width:100%;
    display:table-cell;
    vertical-align:middle;
    text-align:center;
    overflow:hidden;
}
.text-center{
    text-align:center!important;
}
.due-emoji-mat{
    font-size:34px!important;
    top:7px!important;
    position:absolute!important;
}
.cete-logo-text{
    font-family:'Comfortaa',cursive;
}
.cete-logo{
    height:80%;
    width:auto;
    position:relative;
    top:50%;
    -webkit-transform:translateY(-50%);
    -ms-transform:translateY(-50%);
    transform:translateY(-50%);
}
.cete-id{
    font-size:10px;
    background:#254663;
    padding:2px;
}
.h1-catch-phrase{
    color:#f1f1f1;
    font-size:50px;
}
#invite-badge{
    left:22px;
}
#add-user{
    height:250px;
    width:400px;
    z-index:11;
    visibility:visible;
    display:none;
}
.mdl-layout__content{
    padding-left:12px;
    padding-right:12px;
    padding-top:24px;
    padding-bottom:24px;
}
.page-content{
    padding-bottom:24px;
}
.mdl-button--fit-card{
    width:95%;
    margin:9px;
}
#invite-list{
    width:340px;
    max-height:500px;
    overflow:hidden;
    overflow-y:auto;
}
.invite-name{
    display:block;
    width:150px;
    overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
}
.invites-title{
    color:rgba(249,241,223,.87);
    margin-top:4px;
    margin-left:16px;
}
.accept-decline-invite{
    margin-left:auto;
}
#override-font{
    font-size:inherit;
}
#cete-header-buttons{
    position:relative;
    margin-left:auto;
}
.login-register{
    font-size:12px;
    padding-top:7px;
}
.member-list{
    position:relative;
}
.award-card{
    width:240px;
    height:40px;
    margin-right:6px;
    margin-top:6px;
}
.normal-award{
    background:#575B76;
}
.special-award{
    background:#765784;
}
#awards-list{
    margin-top:12px;
    max-height:calc(100% - 56px);
    height:calc(100% - 44px);
    overflow-x:hidden;
    position:absolute;
    width:calc(100% - 24px);
    /* background-image:url('/web/20170829025830im_/https://dueutil.tech/img/pattern.svg'); */
    padding-left:0px;
}
.award-image{
    float:left;
    margin:5px;
}
.award-message{
    font-size:11px;
}
.award-text{
    margin-top:7px;
    line-height:12px;
}
#page-header{
    text-overflow:ellipsis;
}

#command-categories {
  /* position: fixed; */
}

/*.command-list,*/.award-list{
    display:-webkit-flex;
    display:-ms-flexbox;
    display:flex;
    -webkit-flex-wrap:wrap;
    -ms-flex-wrap:wrap;
    flex-wrap:wrap;
    justify-content:center;
}
.command-list-m{
    display:-ms-flexbox;
    -ms-flex-direction:column;
    -ms-flex-wrap:wrap;
    display:flex;
    flex-direction:column;
    flex-wrap:wrap;
    height:100vw;
    font-size:0;
    justify-content:center;
}
.admin-command{
    right:16px;
    z-index:6;
}
#banner{
    margin-top:32px;
    width:100%;
    text-align:center;
    padding:12px;
    background:rgba(149,211,189,1);
    margin-left:-12px;
    margin-right:-12px;
}
#banner-under{
    width:100%;
    position:absolute;
    height:258px;
    top:240px;
    z-index:-5;
    background:#575b76;
    background:-moz-linear-gradient(left,#575b76 0%,#95d3bd 22%,#95d3bd 25%,#95d3bd 35%,#95d3bd 78%,#575b76 100%);
    background:-webkit-linear-gradient(left,#575b76 0%,#95d3bd 22%,#95d3bd 25%,#95d3bd 35%,#95d3bd 78%,#575b76 100%);
    background:linear-gradient(to right,#575b76 0%,#95d3bd 22%,#95d3bd 25%,#95d3bd 35%,#95d3bd 78%,#575b76 100%);
    filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#575b76',endColorstr='#575b76',GradientType=1);
    left:0px;
}
.home-info{
    padding:24px;
    width:260px;
}
.partner-info{
    padding:24px;
    width:260px;
    flex-grow:0.18!important;
    min-width:485px;
    max-width:701px;
    padding-bottom:1px;
}
.partner-img{
    width:150px;
    height:150px;
    border-style:solid;
    border-color:#797d98;
    border-width:2px;
}
.home-info-inner{
    margin-top:10px;
    margin-bottom:10px;
}
.big-p{
    line-height:130%!important;
}
#due-footer{
    position:relative;
    flex-direction:column;
    margin-left:-12px;
    margin-right:-12px;
    margin-bottom:auto;
    height:10px;
    background:none;
    bottom:0;
    left:0;
}
.discord-avatar{
    height:64px;
    width:auto;
}
#overlay{
    display:none;
    background-color:rgba(0,0,0,.5);
    position:fixed;
    width:100%;
    height:100%;
    top:0px;
    left:0px;
    z-index:10;
}
.rank{
    padding-top:6px;
    width:45px;
    height:32px;
    /* background-image:url('/web/20170829025830im_/https://dueutil.tech/img/rankthing.png'); */
    background-size:45px 32px;
    background-repeat:no-repeat;
    color:#1a344b;
    text-align:center;
    font-size:24px;
    position:relative;
}
.rank-inner{
    height:32px;
    width:32px;
    right:12px;
    text-align:center;
    position:absolute;
}
.leaderboard-row:hover{
    background-color:#575B76!important;
}
