@media only screen and (max-width: 970px) {
  #page-title {
    font-size: 2em !important;
  }

  #header header {
    justify-content: space-evenly;
  }

  #player-admin h1, #choice-or{
     font-size: 1.7em;
  }

  #next-page p, #previous-page p {
    padding: 0.2em;
  }

  .shrinky-padding__commands {
    width: 0px;
  }

  #due-landing h1 {
    font-size: 2.3em;
  }

  #due-landing__dorito h2 {
    font-size: 1.5em;
  }

  #due-landing__dorito__arrows__arrow {
    width: 110px;
    height: 110px; /* fix for mac */
  }

  .due-landing__dorito-btn {
    width: 5em;
  }

  #due-landing__dorito {
    margin-left: 0;
    margin-right: 0;
  }

  .donate-wrapper {
    margin-top: 55px;
  }
}

@media only screen and (max-width: 500px) {
  #guide-navigation {
    grid-template-areas: "right" "left";
    grid-row-gap: 1em;
  }

  #next-page p, #previous-page p {
    font-size: 1.1em;
  }
}

@media only screen and (max-width: 405px) {
  #due-landing__dorito__desc {
    margin-top: -1px;
  }
}
