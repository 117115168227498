
#categories {
  display: flex;
  flex-direction: column;
  margin-right: 24px;
  position: fixed;
}

#categories__mobile {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 22px;
  margin-bottom: 22px;
}

.shrinky-padding {
  width: 500px;
  flex-shrink: 4;
}

#player-guide {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  margin-top: 34px;
}

#player-guide__mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#dummy-buttons {
  width: 144px;
  flex-shrink: 0;
}

.rc-tooltip-inner {
  min-height: 0px !important;
}

.choice-content {
  cursor: pointer;
}

#categories div {
  margin-bottom: 20px;
}

.start-content img {
  width: 90%;
  /* height: 180px; */
}

.selected-guide__desktop {
  width: 120px;
  height: 120px;
}

.unselected-guide__desktop, .selected-guide__mobile  {
  width: 80px;
  height: 80px;
}

.selected-guide__desktop, .unselected-guide__desktop,
.selected-guide__mobile, .unselected-guide__mobile
{
  cursor: pointer;
}

.unselected-guide__mobile {
  width: 70px;
  height: 70px;
}

.selected-guide__mobile, .unselected-guide__mobile {
  margin: 6px;
}

.guide-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 11px;
  background: #fe9268;
  box-shadow: 4px 4px 7px rgba(97, 84, 79, 0.842),
    -4px -4px 7px rgba(232, 241, 239, 0.356);
}

.guide-button img {
  width: 80%;
}

.content {
  width: calc(100% - 120px - 6em);
}

.mobile-content {
  padding-left: 1em;
  padding-right: 1em;
}

.mobile-content, .content {
  margin-bottom: 24px;
}

#guide {
  /* margin-top: 20px; */
  /* width: 900px;
  height: 550px; */
  padding: 20px;
  padding-bottom: 12px;
  background-color: #0e8e80;
  border-radius: 20px;
}
