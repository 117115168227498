@font-face {
  font-family: 'Hey Fun';
  src: url('../fonts/HeyFun.woff2') format('woff2'),
      url('../fonts/HeyFun.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'benfont';
  src: url('../fonts/benfont.woff2') format('woff2'),
      url('../fonts/benfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

#not-found {
  background-image: url(../images/white_pattern.svg);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: 'Hey Fun';
}

#not-found > h3 {
  color: #359588;
  padding-left: .8em;
  padding-right: .8em;
  margin: 1em;
  font-size: 2em;
  border-radius: 20px;
  background-color: #F9F9F9;
  font-weight: normal !important;
  text-align: center;
}

#not-found button {
  background-color: #0E6E64;
  color: white;
  margin: unset;
  font-size: 2.6em !important;
  padding: 0.4em !important;
  border-radius: 20px;
  width: auto;
}

#not-found button:active {
  box-shadow: inset 4px 4px 7px rgba(97, 84, 79, 0.842);
}

#error-404{
  width: 70%;
  min-width: 300px;
  max-width: 800px;
  /* background-color: #F9F9F9;
  padding: 24px;
  border-radius: 100px;
  overflow: visible; */
}

#under-construction {
  background-image: url(../images/blue_pattern.svg);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: 'Hey Fun';
}

#under-construction button {
  background-color: #F1E471;
  color: #302D23;
  margin: unset;
  font-size: 2.6em !important;
  padding: 0.4em !important;
  border-radius: 20px;
  width: auto;
}

#page-under-construction{
  width: 70%;
  min-width: 300px;
  max-width: 800px;
  /* background-color: #F9F9F9;
  padding: 24px;
  border-radius: 100px;
  overflow: visible; */
}


#main-content {
  position: relative;
}

#main-content > div:first-of-type {
  min-height: 100%;
}

#footer-credits {
  padding: 6px;
}

#footer-credits__ana {
  font-size: 0.5em;
  margin: 0;
}

footer {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  /* flex-wrap: wrap; */
  padding: 12px;
  padding-bottom: 0;
  background-color: #0e8e80;
  /* height: 2.5em; */
  /* position: absolute; */
  /* bottom: 0; */
  /* position:absolute;
  bottom: 0;
  width: 100%; */
}

#footer-logo, .smol-due-logo {
  font-family: 'benfont';
  margin: 6px;
}

.discord-mention {
  color: #7289da;
}

html {
  overflow-x: hidden;
}

body {
  margin: 0;
  background-color: #17cdb9;
  font-family: 'Roboto', sans-serif;

}

html, body, #root {
  height: 100%;
  overflow-y: hidden;
}

#root {
  display: flex;
  flex-flow: column;
}

#due-landing {
  width: 100%;
  /* height: 100%; */
  /* background-color: #16cdb96b;
  background-image: url(../images/pattern.svg); */
}

#main-content {
  background-image: url(../images/pattern.svg);
  width: 100%;
  height: 100%;
  /* padding-bottom: 12px; */
  overflow-y: auto;
}

span.line {
  display: inline-block;
}

#main-cotent > div {
  overflow-y: hidden;
}

.text {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#player-admin section {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  text-align: center;
  padding-left: 30%;
  padding-right: 30%;
  padding-bottom: 24px;
  /* min-width: 40em; */
}

#player-admin h1 {
  color: white;
}

/* .discord-message {
  z-index: -2;
} */

#player-admin h1, #player-admin h3, #guide-title h1, h2, #command-page h1{
  font-family: 'Hey Fun';
  font-weight: normal !important;
}

#player-admin h3, #guide-title h1, #command-categories h2 {
  white-space: nowrap;
  padding-left: 0.8em;
  padding-right: 0.8em;
  margin: 1em;
  font-size: 2em;
  border-radius: 20px;
  background-color: #0e8e80;
  color: #f6dccb;
}

#player-guide h2,
#player-guide h3,
#player-guide__mobile h2,
#player-guide__mobile h3 {
  margin-bottom: .5em;
}

.at-due-commander {
  color: rgb(255, 197, 57);
}

#player img, #commands img {
  width: 90%;
}

#player-guide__mobile .discord-message > img,
#admin-guide__mobile .discord-message > img {
  width: auto;
  height : auto;
  max-height: 100%;
  max-width: 100%;
}

#admin img {
  width: 80%;
}

#player-guide li {
  margin-top: 6px;
  margin-bottom: 6px;
}

.discord-message > img{
  display:block !important;
}

.choice-content {
  width: 30vw;
  height: 30vw;
  max-height: 15em;
  max-width: 15em;
  min-width: 80px;
  min-height: 80px;
  border: none;
  border-radius: 10px;
  background: #fe9268;
  box-shadow: 4px 4px 7px rgba(97, 84, 79, 0.842),
    -4px -4px 7px rgba(232, 241, 239, 0.356);
}

#choice-or {
  font-family: 'Hey Fun';
  color: white;
  /* font-weight: bold; */
  padding-top: 1em;
  padding-left: 20px;
  padding-right: 20px;
  width: 10em;
  /* margin-left: 1.4em;
  margin-right: 1.4em; */
  margin-top: 2em;
}

#player-admin h1, #choice-or {
  margin: 0.1em;
  font-size: 2.5em;
}

#page-title {
  margin: 0;
  margin-top: 0.8em !important;
}

.botlist_icon img {
  width: 90%;
}

.botlist_smol_title {
  font-size: 1.4em !important;
  line-height: 2em !important;
  white-space: nowrap;
}

.row-container, .button-content__mobile, #choice-or {
  display: flex;
  justify-content: center;
  align-items: center;
}

a:link {
  color: #FBDDC9;
}
a:visited {
  color: #FFE8B1;
}
a:active {
  color: #F8F4F2;
}

#landing-wigets {
  /* position: absolute; */
  /* right: 5%;
  bottom: 48px; */
  padding-bottom: 24px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

#landing-wigets img {
  margin: 12px;
  border-style: solid;
  border-radius: 11px;
  border-width: 5px;
}

/* Slider */

.horizontal-slider {
  width: 100%;
  max-width: 500px;
  height: 50px;
  /* border: 1px solid grey; */
}

.example-thumb {
  font-size: 0.9em;
  text-align: center;
  background-color: #FE9268;
  color: white;
  cursor: pointer;
  border-radius: 10px;
  /* border: 5px solid gray; */
  box-sizing: border-box;
}

.example-thumb.active {
  /* background-color: grey; */
}

.example-track {
  position: relative;
  background: #FFFF;
  border-radius: 5px;
}

/* .example-track.example-track-1 {
  background: #f00;
}

.example-track.example-track-2 {
  background: #0f0;
} */

.horizontal-slider .example-track {
  top: 20px;
  height: 10px;
}

.horizontal-slider .example-thumb {
  top: 7px;
  width: 50px;
  height: 36px;
  line-height: 38px;
}

.vertical-slider .example-thumb {
  left: 1px;
  width: 48px;
  line-height: 40px;
  height: 50px;
}

.vertical-slider .example-track {
  left: 20px;
  width: 10px;
}

.discord-input__wrapper {
  margin: 0.5em 0 0.5em 0;
}

.discord-input {
  display: inline-block;
  padding: 0.5em;
  border-radius: 8px;
  margin-top: 4px;
  margin-bottom: 10px;
  background-color: #40444b;
  color: #dcddde !important;
}

.discord-input a:link{
  color:#00b0f4 !important;
}

.copy-example-button {
  display: flex;
  align-items: center;
  border: none;
  padding: 0.5em;
  border-radius: 6px;
  background: #EAEBF3;
  color: #359588;
  font-weight: 800;
  box-shadow: 4px 4px 8px#0c796d, -4px -4px 8px #10a393;
  margin-bottom: 18px;
}

.copy-example-button:hover{
  box-shadow:  2px 2px 4px#0c796d, -2px -2px 4px#10a393;
}

.copy-example-button:active{
  box-shadow: inset 2px 2px 7px rgba(97, 84, 79, 0.842),
  inset -2px -2px 7px rgba(232, 241, 239, 0.356);
}

#guide-navigation {
  margin-top: 1em;
  display:grid;
  grid-template-areas: "left right";
  align-items: center;
}

#next-page {
  display:grid;
  grid-area: right;
  text-align: right;
}

#previous-page {
  display:grid;
  grid-area: left;
  text-align: left;
}

#next-page p,
#previous-page p {
  display: flex;
  align-items: center;
  color: white;
  margin: 0;
  padding: 0.3em;
  font-size: 1.5em;
  font-weight: 300;
  font-family: 'Hey Fun';
}

.nav-page-button {
  border: none;
  background-color: transparent;
  outline: none;
  box-shadow:  5px 5px 10px #0c796d, -5px -5px 10px #10a393;
  border-radius: 8px;
}

.nav-page-button:hover{
  box-shadow:  3px 3px 7px #0c796d, -3px -3px 7px #10a393;
}

.nav-page-button:active{
  box-shadow: inset 2px 2px 7px#0c796d,
  inset -2px -2px 7px #10a393;
}

.modal, .cookie-plugin-modal {
  color: black;
}
