.donate-wrapper, .donor-slime {
  left: 50%;
  transform: translateX(-50%);
}

.donate-wrapper {
  margin-top: 95px;
  margin-bottom: -48px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.donor-slime {
  position: absolute;
  right: calc((10em - 80px) / 2);
  background-image: url('../../images/slime.png');
  width: 80px;
  height: 20px;
  background-size: 80px 80px;
  top: -20px;
}

.donate-wrapper:hover .donor-slime, .donate-wrapper:active .donor-slime {
  animation: slime-peek 0.5s forwards;
  -webkit-animation: slime-peek 0.5s forwards;
}

@keyframes slime-peek {
  100% {
    top: -70px;
    height: 70px;
  }
}

@-webkit-keyframes  slime-peek {
  100% {
    top: -70px;
    height: 70px;
  }
}

.donate-wrapper{
  animation: donor-ripple-glow 0.6s linear infinite;
  -webkit-animation:  donor-ripple-glow 0.6s linear infinite;
  border-radius: 10px;
}

.donor-button {
  background-color: #ffc539;
  color: #2e2f2d;
  width: 10em !important;
}

@-webkit-keyframes donor-ripple-glow {
  0% {
    box-shadow: 0 4px 10px rgba(255, 230, 154, 0.1), 0 0 0 0 rgba(255, 230, 154, 0.1), 0 0 0 5px rgba(255, 230, 154, 0.1), 0 0 0 10px rgba(255, 230, 154, 0.1);
  }
  100% {
    box-shadow: 0 4px 10px rgba(255, 230, 154, 0.1), 0 0 0 5px rgba(255, 230, 154, 0.1), 0 0 0 10px rgba(255, 230, 154, 0.1), 0 0 0 20px rgba(255, 230, 154, 0);
  }
}

@keyframes donor-ripple-glow {
  0% {
    box-shadow: 0 4px 10px rgba(255, 230, 154, 0.1), 0 0 0 0 rgba(255, 230, 154, 0.1), 0 0 0 5px rgba(255, 230, 154, 0.1), 0 0 0 10px rgba(255, 230, 154, 0.1);
  }
  100% {
    box-shadow: 0 4px 10px rgba(255, 230, 154, 0.1), 0 0 0 5px rgba(255, 230, 154, 0.1), 0 0 0 10px rgba(255, 230, 154, 0.1), 0 0 0 20px rgba(255, 230, 154, 0);
  }
}



