@media only screen and (max-width: 1230px) {
  .reward-text {
    display: flex;
    font-size: 0.8em;
    padding: 1em;
  }
  .help-due {
    font-size: 1.6em;
    /* border-radius: 15px; */
    padding: 0.5em;
  }
  #vote-review h1 {
    white-space: nowrap;
    padding-left: 0.5em;
    padding-right: 0.5em;
    font-size: 2em;
    border-radius: 10px;
  }
  .botlist_icon .choice-content {
    width: calc(2em + 5vw);
    height: calc(2em + 5vw);
    background: #fe9268;
    border-radius: 15px;
  }
}

@media only screen and (max-width: 970px) {
  .grid-wrapper {
    grid-column-gap: 0em;
    margin: 1em 0em 1em 0em;
    grid-template-areas:
    "vote-for vote-for"
    "help-due help-due"
    "vote-at rewards"
    "vote-buttons vote-rewards"
    "review-at ."
    "review-button review-rewards";
  }
  .reward-text {
    font-size: calc(0.75em + 0.5vw);
    padding: calc(0.6em + 0.6vw);
  }

  #vote-buttons {
    grid-template-columns: 7em;
    grid-template-rows: calc(8em + 5vw) calc(8em + 5vw) calc(8em + 5vw);
  }

  .vote-reward-grid-wrapper {
    display: grid;
    grid-row-gap: 0.5em;
    grid-template-columns: 14em;
    grid-template-rows: repeat(8, 4em);
    text-align: center;
  }

  .review-reward-grid-wrapper {
    display: grid;
    grid-row-gap: 0.5em;
    grid-template-columns: 14em;
    grid-template-rows: repeat(6, 4em);
    text-align: center;
  }
}

@media only screen and (max-width: 426px) {
  .grid-wrapper {
    margin: .2em 0em .2em 0em;
  }

  .help-due {
    font-size: 1.2em;
    /* border-radius: 15px; */
    padding: 0.5em;
  }

  #vote-review h1 {
    white-space: nowrap;
    padding-left: 0.5em;
    padding-right: 0.5em;
    font-size: calc(1em + 1vw);
    border-radius: calc(1em + 0.5vw);
  }
  .reward-text {
    font-size: calc(0.65em + 0.5vw);
    padding: calc(0.5em + 0.6vw);
  }

  .botlist_icon .choice-content {
    width: calc(1em + 5vw);
    height: calc(1em + 5vw);
    background: #fe9268;
    border-radius: 15px;
  }
}

@keyframes shrink {
  0% {
    transform: scale(0.8);
}

50% {
  transform: scale(1);
}

100% {
  transform: scale(0.8);
  }
}
