#command-page {
  scroll-behavior: smooth;
  display: grid;
  grid-template-columns: 320px;
  grid-gap: 1em;
  grid-template-areas:
  "command-guide command-guide"
  "command-categories command-list"
  ". command-list";
  width: 75%;
  margin: 0 auto;
  margin-bottom: 100px;
}

#commands-guide {
  position:relative;
  grid-area: command-guide;
  background: #0E6E64;
  border-radius: 12px;
  line-height: 1.5;
  height: auto;
  padding: 1em;
  margin-top: 1em;
}

#command-categories {
  position: -webkit-sticky;
  position: sticky;
  top: 1em;
  grid-area: command-categories;
  text-align: center;
  display:inline-block;
  padding: 0.5em;
  background-color: #0E6E64;
  border-radius: 12px;
  height:auto;
  /* max-height: 530px;
  max-width: 300px; */
}

#command-page hr {
  background: #FE9268;
  border: none;
  border-radius: 4px;
  height: 4px;
}

#command-list {
  width: 100%;
  min-height: 600px;
  grid-area: command-list;
}

.command-card {
  background-color: #0e8e80;
  border-radius: 12px;
  padding: 12px;
  margin-bottom: 1em;
  overflow: hidden;
}

.command-card__header {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-areas:
  "command desc perm"
  ". desc .";
}

#command-list h1 {
  margin-left: 12px !important;
}

.command-card h3,
.command-card h2 {
  margin: 0;
}

.command-card h3,
#command-categories h2,
.command-alias,
.command-card h2,
.command-card h4,
.command-card__alias_perms,
.command-button,
.command-button-s,
.command-button-active {
  font-family: 'Roboto';
  color: white;
  font-weight: normal !important;
}

.special-buttons {
  display: grid;
  grid-template-areas:
  "expand-all shrink-all"
  "command-search command-search";
  justify-items: center;
}

.command-search {
  grid-area: command-search;
  width: 90%;
  background-color: #0e8e80;
  font-size: 1em;
  height: 2em;
  padding: 0 4px 0 4px;
  margin: 2px 0 6px 0;
  color: #fff;
  border: 2px solid #359588;
  border-radius: 3px;
  transition: background-color .15s ease,border .15s ease;
  writing-mode: horizontal-tb !important;
  display: inline-block;
  text-align: start;
  -webkit-appearance: textfield;
  -webkit-rtl-ordering: logical;
  -webkit-writing-mode: horizontal-tb !important;
  cursor: text;
}

.button-expand-all {
  grid-area: expand-all;
}

.button-shrink-all {
  grid-area: shrink-all;
}

.command-button,
.command-button-s,
.command-button-active {
  display: inline-block;
  align-self: center;
  text-align: center;
  font-size: 1.5em !important;
  margin: 6px;
  font-family: 'hey fun';
  background-color: #FE9268;
  border-radius: 12px;
  width: 10em;
  border: none;
  box-shadow:  4px 4px 8px #0b5850,
  -4px -4px 8px #118478;
}

.command-button-s {
  font-size: 1.4em !important;
  background-color: #EAEBF3;
  color: #359588;
  width: 6em;
  border: none;
}

.command-button-active {
  background-color:  #EAEBF3;
  color: #FE9268;
}

#command-categories#selected {
  background-color: #eeeeee;
}

#commands-guide h1,
#commands-guide h2,
#commands-guide h3,
#commands-guide p {
  margin: 0;
  padding: 0;
}

#commands-guide h3{
  font-weight: 400;
}

#commands-guide br {
  margin: .2em;
}

.four-px-margin {
  margin: 4px 0 !important;
}

.inherit-fontsize {
  font-size: 1rem;
}

.command-alias,
.command-card__usage h2 {
  display: inline-block;
  border-radius: 6px;
  font-size: 1em;
  padding: 6px;
  background-color: #0E6E64;
}

.command-card__desc h2 {
  display: inline-block;
  font-size: 1em;
  border-radius: 6px;
  padding: 6px;
  margin-left: 1em;
  background-color: #359588;
  white-space: nowrap;
}

.command-card__usage {
  grid-area: command;
}

.command-card__perm h2{
  display: inline-block;
  border-radius: 6px;
  font-size: 1em;
  padding: 6px;
  margin: 0 auto;
  text-transform: capitalize;
  text-align: right;
}

.command-card__perm {
  text-align: center;
  justify-self: right;
}

.command-card__desc {
  align-self: center;
  grid-area: desc;
}

.command-card__content {
  background-color: #0E6E64;
  border-radius: 12px;
  padding: 12px 12px 12px 12px;
  margin-top: 1em;
  grid-area: command-content;
}

.perm-player h2 {
  background-color: #D88A6C;
  grid-area: perm;
}

.perm-due_commander h2 {
  background-color: #BB6392;
}

.perm-server_admin h2 {
  background-color: #6886FE;
}

.command-alias {
  background-color: #36393e85
}

.command-alias {
  margin-top: 10px;
  margin-right: 10px;
}

#command-list h1 {
  display: inline-block;
  font-family: 'Hey Fun';
  font-weight: normal !important;
  white-space: nowrap;
  padding-left: 0.8em;
  padding-right: 0.8em;
  font-size: 2em;
  border-radius: 20px;
  background-color: #0e8e80;
  color: #f6dccb;
}

.command-card h3 {
  margin-bottom: 0;
}

pre {
  white-space: pre-wrap;       /* css-3 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  word-wrap: break-word;       /* Internet Explorer 5.5+ */
 }

 button,
 .command-card__header:hover {
  cursor: pointer;
}

.command-button:hover,
.command-button-s:hover,
.command-button-active:hover {
  /* background-color: #c7653e; */
    /* box-shadow: 2px 2px 8px rgba(97, 84, 79, 0.842),
    -2px -2px 8px rgba(232, 241, 239, 0.356); */
    box-shadow:  2px 2px 8px #0b5850,
    -2px -2px 8px #118478;
}

.command-button:active,
.command-button-s:active,
.command-button-active:active  {
  box-shadow: inset 2px 2px 7px rgba(97, 84, 79, 0.842),
  inset -2px -2px 7px rgba(232, 241, 239, 0.356);
}

::-webkit-input-placeholder {
  color:    #fff;
  opacity:  0.5;
}

:-moz-placeholder {
 color:    #fff;
 opacity:  0.5;
}

::-moz-placeholder {
 color:    #fff;
 opacity:  0.5;
}

:-ms-input-placeholder {
 color:    #fff;
 opacity:  0.5;
}

::-ms-input-placeholder {
 color:    #fff;
 opacity:  0.5;
}

::placeholder {
 color:    #fff;
 opacity:  0.5;
}

.command-arg--optional,
.command-arg__brace--optional {
  color: #EEF281;
}

.command-arg--required,
.command-arg__brace--required {
  color: #FE9A9A
}

.command-arg--conditional,
.command-arg__brace--conditional {
  color: #74D5F9;
}

.command-arg__one-or-more {
  color: yellowgreen;
}

@media only screen and (max-width: 1300px) {
  #command-page {
    width: 92%;
    margin: 0 3em auto;
    margin-bottom: 80px;
  }

  .command-card__header {
    grid-template-areas:
    "command . perm"
    " desc . .";
  }
  .command-card__header h2 {
    white-space: normal;
  }

  /* .command-card__usage h2,
  .command-card__desc h2 {
    max-width: 450px
  } */

  .command-card__desc h2 {
    margin-top: .5em;
    margin-left: 0;
  }

}

@media only screen and (max-width: 970px) {
  #command-page {
  width: 90%;
  grid-gap: 0;
  margin: .2em auto;
  grid-template-areas:
  "command-guide command-guide"
  "command-categories command-categories"
  "command-list command-list";
  }

  #command-categories {
    margin-top: 1em;
    width: auto;
  }

  #command-list {
    width: auto;
  }

  .command-card {
    margin-top: 0px;
  }

  #command-categories {
    position: static;
  }

  .command-card__header h2 {
    white-space: normal;
  }

  .command-card__usage h2,
  .command-card__desc h2 {
    max-width: 220px
  }

  .command-card__perm h2  {
    text-align: left;
    font-size: 0.8em;
  }

  #command-list h1 {
    margin: 16px 0 16px 0
  }

}

@media only screen and (max-width: 340px) {

  #command-page {
    width: 100%;
  }

  .command-search {
    width: 75%;
  }

}

