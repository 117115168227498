.embed,.embed .embed-fields{
  -webkit-box-direction:normal
}
.discord-code{
  background:#2F3136!important
}
.emoji{
  -o-object-fit:contain;
  object-fit:contain;
  width:1rem;
  height:1rem;
  min-width:22px;
  min-height:22px;
  margin:0 .05em 0 .1em!important;
  vertical-align:-.4em
}
.embed-wrapper{
  position:relative;
  margin-top:5px;
  max-width:520px;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex
}
.embed-wrapper .embed-color-pill{
  width:4px;
  background:#cacbce;
  border-radius:3px 0 0 3px;
  -ms-flex-negative:0;
  flex-shrink:0
}
.embed{
  padding:8px 10px;
  box-sizing:border-box;
  background:rgba(46,48,54,.3);
  border:1px solid rgba(46,48,54,.6);
  border-radius:0 3px 3px 0;
  display:flex;
  -webkit-box-orient:vertical;
  -ms-flex-direction:column;
  flex-direction:column
}
.embed div.embed-title{
  color:#FFF
}
.embed .embed-content{
  width:100%;
  display:flex;
  margin-bottom:10px
}
.embed .embed-content .embed-content-inner{
  -webkit-box-flex:1;
  -ms-flex:1;
  flex:1
}
.embed.embed-rich{
  position:relative;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  border-radius:0 3px 3px 0
}
.embed.embed-rich .embed-rich-thumb{
  max-height:80px;
  max-width:80px;
  border-radius:3px;
  width:auto;
  -o-object-fit:contain;
  object-fit:contain;
  -ms-flex-negative:0;
  flex-shrink:0;
  margin-left:20px
}
.embed .embed-content-inner>:last-child,.embed>:last-child{
  margin-bottom:0!important
}
.embed .embed-footer{
  display:inline-block;
  font-weight:500;
  font-size:.75rem;
  color:#A2A3A5;
  letter-spacing:0
}
.embed .embed-title{
  display:inline-block;
  font-weight:500;
  margin-bottom:4px;
  font-size:.875rem
}
.embed .embed-fields{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-orient:horizontal;
  -ms-flex-direction:row;
  flex-direction:row;
  -ms-flex-wrap:wrap;
  flex-wrap:wrap;
  color:#FFF;
  margin-top:-10px;
  margin-bottom:10px
}
.embed .embed-fields .embed-field{
  -webkit-box-flex:0;
  -ms-flex:0;
  flex:0;
  padding-top:10px;
  min-width:100%;
  max-width:506px
}
.embed .embed-fields .embed-field.embed-field-inline{
  -webkit-box-flex:1;
  -ms-flex:1;
  flex:1;
  min-width:150px;
  -ms-flex-preferred-size:auto;
  flex-basis:auto
}
.embed .embed-fields .embed-field .embed-field-name{
  font-size:.875rem;
  margin-bottom:4px;
  font-weight:500
}
.embed .embed-fields .embed-field .embed-field-value{
  font-size:.875rem;
  font-weight:400
}
.need-help-modal.deprecated .header input[type=text]::-webkit-input-placeholder{
  color:#c2ccd3
}
.search-bar::-webkit-input-placeholder{
  color:hsla(0,0%,100%,.2)
}
.search-bar.search-bar-light input::-webkit-input-placeholder{
  color:rgba(115,127,141,.3);
  line-height:15px;
  font-weight:500;
  font-size:14px
}
.theme-light .ui-input-button .input::-webkit-input-placeholder{
  color:rgba(79,84,92,.3)
}
.theme-light .ui-input-button .input:-ms-input-placeholder{
  color:rgba(79,84,92,.3)
}
.theme-light .ui-input-button .input::placeholder{
  color:rgba(79,84,92,.3)
}
.theme-dark .ui-input-button .input::-webkit-input-placeholder{
  color:hsla(240,6%,97%,.3)
}
.theme-dark .ui-input-button .input:-ms-input-placeholder{
  color:hsla(240,6%,97%,.3)
}
.theme-dark .ui-input-button .input::placeholder{
  color:hsla(240,6%,97%,.3)
}
.theme-light .ui-search-bar .input::-webkit-input-placeholder{
  color:rgba(79,84,92,.3)
}
.theme-light .ui-search-bar .input:-ms-input-placeholder{
  color:rgba(79,84,92,.3)
}
.theme-light .ui-search-bar .input::placeholder{
  color:rgba(79,84,92,.3)
}
.theme-dark .ui-search-bar .input::-webkit-input-placeholder{
  color:hsla(0,0%,100%,.3)
}
.theme-dark .ui-search-bar .input:-ms-input-placeholder{
  color:hsla(0,0%,100%,.3)
}
.theme-dark .ui-search-bar .input::placeholder{
  color:hsla(0,0%,100%,.3)
}
.theme-light .ui-prefix-input-input::-webkit-input-placeholder{
  color:#b9bbbe
}
.theme-light .ui-prefix-input-input:-ms-input-placeholder{
  color:#b9bbbe
}
.theme-light .ui-prefix-input-input::placeholder{
  color:#b9bbbe
}
.theme-dark .ui-prefix-input-input::-webkit-input-placeholder{
  color:#72767d
}
.theme-dark .ui-prefix-input-input:-ms-input-placeholder{
  color:#72767d
}
.theme-dark .ui-prefix-input-input::placeholder{
  color:#72767d
}
.fancy-credit-input .cc-info-wrapper input::-webkit-input-placeholder{
  color:rgba(54,57,62,.2)
}
.fancy-credit-input .cc-info-wrapper input:-ms-input-placeholder{
  color:rgba(54,57,62,.2)
}
.fancy-credit-input .cc-info-wrapper .zip-code:focus::-webkit-input-placeholder{
  color:transparent
}
.fancy-credit-input .cc-info-wrapper .zip-code:focus:-ms-input-placeholder{
  color:transparent
}
a img{
  border:none
}
.theme-dark .friends-table .scroller-wrap::-webkit-scrollbar-thumb,.theme-dark .messages-wrapper .scroller-wrap::-webkit-scrollbar-thumb{
  background-color:#1e2124;
  border-color:#36393e
}
.theme-dark .friends-table .scroller-wrap::-webkit-scrollbar-track-piece,.theme-dark .messages-wrapper .scroller-wrap::-webkit-scrollbar-track-piece{
  background-color:#2f3136;
  border-color:#36393e
}
.theme-dark .channel-textarea-inner::-webkit-input-placeholder{
  color:hsla(0,0%,100%,.2)
}
form.deprecated.auth-form .control-group input[type=email]::-webkit-input-placeholder,form.deprecated.auth-form .control-group input[type=password]::-webkit-input-placeholder,form.deprecated.auth-form .control-group input[type=text]::-webkit-input-placeholder{
  color:hsla(0,0%,100%,.4)
}
.theme-light .ui-key-recorder.recording .input::-webkit-input-placeholder{
  color:transparent
}
.theme-light .ui-key-recorder.recording .input:-ms-input-placeholder{
  color:transparent
}
.theme-light .ui-key-recorder.recording .input::placeholder{
  color:transparent
}
.theme-dark .ui-key-recorder.recording .input::-webkit-input-placeholder{
  color:transparent
}
.theme-dark .ui-key-recorder.recording .input:-ms-input-placeholder{
  color:transparent
}
.theme-dark .ui-key-recorder.recording .input::placeholder{
  color:transparent
}
.search-results-wrap .scroller-wrap .scroller::-webkit-scrollbar-track-piece{
  background-color:#dbdde1!important
}
.search-results-wrap .scroller-wrap .scroller::-webkit-scrollbar-thumb{
  background-color:#7289da!important;
  border:3px solid #f3f3f3!important
}
.theme-dark .search-results-wrap .scroller-wrap .scroller::-webkit-scrollbar-track-piece{
  background-color:#282b30!important
}
.theme-dark .search-results-wrap .scroller-wrap .scroller::-webkit-scrollbar-thumb{
  background-color:#1e2124!important;
  border:3px solid #2f3136!important
}
.add-friend-input-wrapper .add-friend-input::-webkit-input-placeholder{
  color:#dadddf
}
.theme-dark .add-friend-input-wrapper .add-friend-input::-webkit-input-placeholder{
  color:hsla(0,0%,100%,.2)
}
.onboarding-slide.app-downloads .text-form input::-webkit-input-placeholder{
  color:hsla(0,0%,100%,.3)
}
.onboarding-slide.app-downloads .text-form input::-moz-input-placeholder{
  color:hsla(0,0%,100%,.3)
}
.onboarding-slide input::-webkit-input-placeholder,.onboarding-slide textarea::-webkit-input-placeholder{
  color:#dadddf;
  font-weight:300
}
.theme-dark .message-group .embed .embed-description,.theme-dark .message-group .embed .embed-fields{
  color:hsla(0,0%,100%,.6);
}
.theme-dark .message-group .comment .markup{
  color:hsla(0,0%,100%,.7);
}
.message-group .comment .markup{
  color:#737f8d;
  font-size:0.9375rem;
  line-height:1.1em;
  white-space:pre-wrap;
  margin-top:6px;
  word-wrap:break-word;
}
.embed .embed-description.markup{
  white-space:pre-line;
  margin-top:0!important;
  font-size:0.875rem!important;
  line-height:1rem!important;
}
.embed .embed-title+.embed-description{
  margin-top:-3px!important;
}
.embed .embed-description{
  display:block;
  font-size:0.875rem;
  font-weight:400;
  margin-bottom:10px;
  color:hsla(0,0%,100%,.6);
  letter-spacing:0;
}
.markup{
  color:hsla(0,0%,100%,.7);
}
