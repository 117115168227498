.title-text {
  text-align: center;
  justify-self: center;
}

.grid-wrapper {
  margin: 1em;
  display: grid;
  grid-gap: 1em;
  align-items: center;
  /*grid-template-columns: 40% 20% 40%;*/
  grid-template-areas: "vote-for vote-at rewards" "help-due vote-buttons vote-rewards" "help-due review-at ." ". review-button review-rewards";
}

.vote-reward-grid-wrapper {
  display: grid;
  grid-row-gap: 0.5em;
  grid-template-columns: 5em 20em;
  grid-template-rows: repeat(4, 4em);
  align-items: center;
}

.review-reward-grid-wrapper {
  display: grid;
  grid-row-gap: 0.5em;
  grid-template-columns: 5em 20em;
  grid-template-rows: repeat(3, 4em);
  align-items: center;
}

#vote-review {
  overflow: hidden;
}

#vote-review .choice-content {
  min-width: 90px;
  min-height: 90px;
}

#vote-rewards img, #vote-rewards h4, #review-rewards img, #review-rewards h4 {
  align-self: center;
  text-align: left;
}

#vote-rewards img, #review-rewards img {
  height: 100%;
  width: 100%;
  object-fit: contain
}

#vote-buttons h2, #review-button h2 {
  font-size: 1.2em;
  margin: auto;
  text-align: center
}

.help-due, #vote-review h3, #vote-review h1 {
  font-family: 'Hey Fun';
  font-weight: normal !important;
}

#vote-review h1 {
  white-space: nowrap;
  padding-left: 0.8em;
  padding-right: 0.8em;
  margin: 0.1em;
  font-size: 2.1em;
  border-radius: 15px;
  background-color: #0e8e80;
  color: #f6dccb;
}

.help-due {
  font-size: 2em;
  border-radius: 20px;
  padding: 0.2em;
  color: #f6dccb;
  /* height: 4em; */
  width: 10em;
  white-space: normal;
  padding-left: 0.8em;
  padding-right: 0.8em;
  margin: 0.1em;
  background-color: #0E6E64;
}

.help-due hr {
  background: #FE9268;
  border: none;
  border-radius: 4px;
  height: 4px;
}

.reward-text {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  border-radius: 20px;
  color: #f6dccb;
  padding: 1em;
  height: auto;
  width: auto;
  background-color: #0E6E64;
}

#vote-button-1 {
  text-align: center;
  grid-row: 1/2;
  grid-column: 1/2;
}

#vote-button-1 .choice-content {
  animation: shadow-pulse 0.95s infinite;
}

#vote-button-2 {
  text-align: center;
  grid-row: 2/3;
  grid-column: 2/3
}

#vote-button-3 {
  text-align: center;
  grid-row: 3/4;
  grid-column: 1/2
}

#vote-for {
  grid-area: vote-for;
  text-align: center;
  justify-self: center;
}

#vote-at {
  grid-area: vote-at;
  text-align: center;
  justify-self: center;
}

#rewards {
  grid-area: rewards;
  text-align: center;
  justify-self: center;
}

.help-due {
  grid-area: help-due;
  text-align: center;
  justify-self: center;
}

#vote-buttons {
  display: grid;
  justify-self: center;
  grid-area: vote-buttons;
  grid-template-columns: 7em 7em;
  grid-template-rows: 10em 10em 10em;
}

#vote-rewards {
  grid-area: vote-rewards;
  justify-self: center;
}

#review-at {
  grid-area: review-at;
  text-align: center;
  justify-self: center;
}

#review-button {
  grid-area: review-button;
  text-align: center;
  justify-self: center;
}

#review-rewards {
  grid-area: review-rewards;
  justify-self: center;
}

.botlist_icon .choice-content {
  width: 8em;
  height: 8em;
  background: #fe9268;
  border-radius: 20px;
}

.reward-image {
    animation: shrink 4s infinite;
}
