#due-landing {
  display: flex;
  overflow-x: hidden;
  flex-direction: row;
  justify-content: center;
  background-attachment: fixed;
  flex-wrap: wrap;
  background-color: #265A6B;
  background-image: url(../../images/pattern_blue.svg);
}

#due-landing h1 {
  font-family: 'benfont';
  font-size: 1.65em;
  margin: 0;
  font-weight: normal;
  color: #EAEBF3
}

#due-landing__dorito {
  position: relative;
  margin: 24px;
  margin-bottom: 90px;
  display: inline-block;
  text-align: center;
}

.due-landing__heading {
  font-family: 'Hey Fun';
  font-weight: normal;
  /* margin-bottom: 0; */
  font-size: 3em;
  letter-spacing: 0.05em;
  color: #EAEBF3
}

#due-landing__dorito__desc {
  padding-left: 10%;
  padding-right: 10%;
  font-size: 0.95em;
  letter-spacing: 0.12em;
  word-spacing: 0.15em;
  color: #E8F1EF
}

#due-landing__dorito__dorito {
  width: 100%;
  max-width: 500px;
  opacity: 0.8;
  min-width: 400px;
}

#due-landing__hero {
  display: inline-block;
}

#due-landing__dorito h2 {
  font-family: 'Hey Fun';
  font-weight: normal;
  color: #f6dccb;
  font-size: 2.1em;
  margin-bottom: 0.7em;
}

#due-landing__dorito__arrows {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: -80px;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

#due-landing__dorito__arrows__arrow {
  width: 150px;
  height: 150px; /* fix for mac */
  z-index: 50;
  flex-shrink: 2;
  margin-right: 24px;
  margin-left: 24px;
}

#due-landing__logo{
  border-radius: 50%;
  border-width: 0.3em;
  border-style: solid;
  border-color: #EAEBF3;
}

.arrow-side-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.random-due {
  margin-top: 48px;
}

.random-due img {
  -ms-interpolation-mode: nearest-neighbor;
  image-rendering: pixelated;
  image-rendering: -moz-crisp-edges;
  image-rendering: crisp-edges;
  width: 7em;
}

.orange-btn {
  background: #FE9268;
  color: white;
}

.white-btn {
  background: #EAEBF3;
  color: #359588;
}

.due-landing__dorito-btn {
  border-radius: 10px;
  font-size: 1.1em !important;
  width: 10em;
  font-family: 'Hey Fun';
  padding-bottom: 0.5em;
  padding-top: 0.5em;
  font-size: 1.2em;
  border: none;
  margin: auto;
  cursor: pointer;
}

.flex-break {
  flex-basis: 100%;
  height: 0;
}

#due-landing__features {
  /* margin-top: 24px; */
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-left: 10%;
  padding-right: 10%;
}

.due-landing__feature {
  margin: 12px;
  max-width:100%; height:auto;
  align-self: flex-start;
  /* image-rendering: pixelated;
  image-rendering: crisp-edges; */
}
